import React from "react";
import Layout from "../components/common/layout";

const Testimonials = () => (
  <Layout>
    <h1>Testimonials</h1>
  </Layout>
);

export default Testimonials;
